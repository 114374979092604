import { APP_VERSION, IS_DEV, SENTRY_DSN } from '@/config/constants';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export const initSentry = () => {
  if (IS_DEV) return;

  // https://docs.sentry.io/platforms/javascript/guides/react/
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      new BrowserTracing(),

      // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/
      new Sentry.Replay(),
    ],

    /*
     * Set tracesSampleRate to 1.0 to capture 100%
     * of transactions for performance monitoring.
     * We recommend adjusting this value in production
     */
    release: APP_VERSION,
    tracesSampleRate: 1.0,
  });
};
