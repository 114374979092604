import { Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { t } from '@/services/translator';
import { message } from 'antd';
import { AppContext } from '@/components/AppContext';

import { Button } from '@/components/common/button/Button';
import { SpinFullscreen } from '@/components/common/spinner/SpinFullscreen';
import { emptyApi } from '@/store/emptyApi';

import { withTranslation } from 'react-i18next';
import { useAuth } from '@/store/auth/authHooks';
import { useTrackBreakpoint } from '@/hooks/breakpoints/useTrackBreakpoint';
import { useAppDispatch } from '@/hooks/redux';
import { useAppVersion } from '@/shared/hooks/useAppVersion';

import { MagicLogin } from './common/MagicLogin';
import { ErrorPage } from '@/components/common/errors/ErrorPage';
import { AppMainRoutes } from '@/components/AppMainRoutes';

export const App = withTranslation()(() => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const [prevUserId, setUserId] = useState<number>(user.id);

  const { reloadRequired } = useAppVersion();

  const breakpoint = useTrackBreakpoint();

  useEffect(() => {
    if (prevUserId && user.id && prevUserId !== user.id) {
      dispatch(emptyApi.util.resetApiState());
    }

    if (user.id) {
      setUserId(user.id);
    }
  }, [dispatch, prevUserId, user.id]);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!reloadRequired) return;
    message.warn({
      duration: 0,
      content: (
        <div
          style={{
            display: 'inline-block',
            textAlign: 'left',
            verticalAlign: 'top',
          }}
        >
          {t('misc.applicationUpdated')}{' '}
          <Button onClick={() => window.location.reload()} type="primary" size="small">
            {t('errors.reloadPage')}
          </Button>
        </div>
      ),
    });
  }, [reloadRequired]);

  return (
    <AppContext.Provider value={{ breakpoint }}>
      <Routes>
        <Route path="/magic-login/:magicUuid" element={<MagicLogin />} />
        {/* Render errors page for QA
         * TODO: Should be deleted after a few weeks...
         */}
        {[404, 500, 503].map((code) => (
          <Route key={code} path={`${code}`} element={<ErrorPage code={code} />} />
        ))}
        <Route
          path="*"
          element={
            <Suspense fallback={<SpinFullscreen />}>
              <AppMainRoutes />
            </Suspense>
          }
        />
      </Routes>
    </AppContext.Provider>
  );
});
