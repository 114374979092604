import { FEATURE, Feature } from '@/config/features';
import { Navigate } from 'react-router-dom';
import React, { lazy } from 'react';
import { lazyRetry } from '@/components/common/errors/lazyRetry';

export interface RouteItem {
  feature: Feature;
  single?: boolean; // flag isSingle view
  element: React.LazyExoticComponent<React.ComponentType<any>> | React.ComponentType<any>;
}

const ProfilePage = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "ProfilePage" */ '@feature/profile/ProfilePage'), 'ProfilePage'),
);
const WriterOrderSinglePage = lazy(() =>
  lazyRetry(
    () =>
      import(/* webpackChunkName: "WriterOrderSinglePage" */ '@/components/features/order/writerOrder/singlePage/WriterOrderSinglePage'),
    'WriterOrderSinglePage',
  ),
);
const WriterPassSinglePage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "WriterPassSinglePage" */ '@feature/writerPass/WriterPassSinglePage'),
    'WriterPassSinglePage',
  ),
);
const WriterOrderSearchPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "WriterOrderSearchPage" */ '@/components/features/order/writerOrder/WriterOrderSearchPage'),
    'OrdersSearchPage',
  ),
);
const AvailableOrderSearchPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "AvailableOrderSearchPage" */ '@feature/order/availableOrder/AvailableOrderSearchPage'),
    'AvailableOrderSearchPage',
  ),
);
const AvailableOrderSinglePage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "AvailableOrderSinglePage" */ '@feature/order/availableOrder/AvailableOrderSinglePage'),
    'AvailableOrderSinglePage',
  ),
);
const RevisionSearchPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "RevisionSearchPage" */ '@/components/features/revision/RevisionSearchPage'),
    'RevisionSearchPage',
  ),
);
const RevisionSinglePage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "RevisionSinglePage" */ '@/components/features/revision/RevisionSinglePage'),
    'RevisionSinglePage',
  ),
);
const GradingSearchPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "GradingSearchPage" */ '@/components/features/grading/GradingSearchPage'),
    'GradingSearchPage',
  ),
);
const GradingSinglePage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "GradingSinglePage" */ '@/components/features/grading/GradingSinglePage'),
    'GradingSinglePage',
  ),
);
const TicketSearchPage = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "TicketSearchPage" */ '@/components/features/ticket/TicketSearchPage'), 'TicketSearchPage'),
);
const TicketSinglePage = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "TicketSinglePage" */ '@/components/features/ticket/TicketSinglePage'), 'TicketSinglePage'),
);

const WriterPaymentBatchSinglePage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "WriterPaymentBatchSinglePage" */ '@/components/features/writerPaymentBatch/WriterPaymentBatchSinglePage'
      ),
    'WriterPaymentBatchSinglePage',
  ),
);

// const FeedbackSearchPage = _(import('@/components/features/feedback/FeedbackSearchPage'));

const TermsAndConditionsPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "TermsAndConditionsPage" */ '@/components/common/staticPages/TermsPage'),
    'TermsAndConditionsPage',
  ),
);
const WikiPage = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "WikiPage" */ '@/components/common/staticPages/WikiPage'), 'WikiPage'),
);
const FAQPage = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "FAQPage" */ '@/components/features/writerManual/WriterManualPage'), 'FAQPage'),
);

const KnowledgeBasePage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "KnowledgeBasePage" */ '@/components/common/staticPages/KnowledgeBasePage'),
    'KnowledgeBasePage',
  ),
);

const PlagCheckSearchPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "PlagAICheckSearchPage" */ '@feature/plagAICheck/PlagAICheckSearchPage'),
    'PlagCheckSearchPage',
  ),
);

const PlagCheckSinglePage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "PlagAICheckSinglePage" */ '@feature/plagAICheck/PlagAICheckSinglePage'),
    'PlagCheckSinglePage',
  ),
);
const WriterFieldCompetenceSinglePage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "WriterFieldCompetenceSinglePage" */ '@/components/features/writerFieldCompetence/WriterFieldCompetenceSinglePage'
      ),
    'WriterFieldCompetenceSinglePage',
  ),
);

const BroadcastMessageSearchPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "BroadcastMessageSearchPage" */ '@/components/features/broadcastMessage/BroadcastMessageSearchPage'),
    'BroadcastMessageSearchPage',
  ),
);

const NotificationSearchPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "NotificationsSearchPage" */ '@/components/features/notification/NotificationSearchPage'),
    'NotificationsSearchPage',
  ),
);

const OrderDrawingSearchPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "OrderDrawingSearchPage" */ '@feature/orderDrawing/OrderDrawingSearchPage'),
    'OrderDrawingSearchPage',
  ),
);
const OrderDrawingSinglePage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "OrderDrawingSinglePage" */ '@feature/orderDrawing/OrderDrawingSinglePage'),
    'OrderDrawingSinglePage',
  ),
);

const OrderOfferSearchPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "OrderOfferSearchPage" */ '@feature/orderDrawing/OrderOfferSearchPage'),
    'OrderOfferSearchPage',
  ),
);

export const routes: RouteItem[] = [
  {
    element: ProfilePage,
    feature: FEATURE.Profile,
  },
  {
    element: () => <Navigate to={`/?tab=writer-passes`} />,
    feature: FEATURE.WriterPass,
  },
  {
    element: WriterPassSinglePage,
    feature: FEATURE.WriterPass,
    single: true,
  },
  {
    element: PlagCheckSearchPage,
    feature: FEATURE.PlagCheck,
  },
  {
    element: PlagCheckSinglePage,
    feature: FEATURE.PlagCheck,
    single: true,
  },
  {
    element: PlagCheckSearchPage,
    feature: FEATURE.AICheck,
  },
  {
    element: PlagCheckSinglePage,
    feature: FEATURE.AICheck,
    single: true,
  },
  {
    element: WriterOrderSearchPage,
    feature: FEATURE.ActiveWriterOrder,
  },
  {
    element: WriterOrderSinglePage,
    feature: FEATURE.ActiveWriterOrder,
    single: true,
  },
  {
    element: WriterOrderSearchPage,
    feature: FEATURE.InactiveWriterOrder,
  },
  {
    element: WriterOrderSinglePage,
    feature: FEATURE.InactiveWriterOrder,
    single: true,
  },
  {
    element: AvailableOrderSearchPage,
    feature: FEATURE.AvailableOrder,
  },
  {
    element: AvailableOrderSearchPage,
    feature: FEATURE.ClaimedWriterOrder,
  },
  {
    element: WriterOrderSinglePage,
    feature: FEATURE.ClaimedWriterOrder,
    single: true,
  },
  {
    element: AvailableOrderSinglePage,
    feature: FEATURE.AvailableOrder,
    single: true,
  },
  {
    element: RevisionSearchPage,
    feature: FEATURE.Revision,
  },
  {
    element: RevisionSinglePage,
    feature: FEATURE.Revision,
    single: true,
  },
  {
    element: GradingSearchPage,
    feature: FEATURE.Grading,
  },
  {
    element: GradingSinglePage,
    feature: FEATURE.Grading,
    single: true,
  },
  {
    element: () => <Navigate to={`/?tab=payment-batches`} />,
    feature: FEATURE.WriterPaymentBatch,
  },
  {
    element: WriterPaymentBatchSinglePage,
    feature: FEATURE.WriterPaymentBatch,
    single: true,
  },
  {
    element: TicketSearchPage,
    feature: FEATURE.Ticket,
  },
  {
    element: TicketSinglePage,
    feature: FEATURE.Ticket,
    single: true,
  },
  {
    element: FAQPage,
    feature: FEATURE.FAQ,
  },
  {
    element: TermsAndConditionsPage,
    feature: FEATURE.Terms,
  },
  {
    element: WikiPage,
    feature: FEATURE.Wiki,
  },
  {
    element: KnowledgeBasePage,
    feature: FEATURE.KnowledgeBase,
  },
  {
    element: NotificationSearchPage,
    feature: FEATURE.Notifications,
  },
  {
    element: () => <Navigate to={`/?tab=field-competences`} />,
    feature: FEATURE.WriterFieldCompetence,
  },
  {
    element: WriterFieldCompetenceSinglePage,
    feature: FEATURE.WriterFieldCompetence,
    single: true,
  },
  {
    element: BroadcastMessageSearchPage,
    feature: FEATURE.ImportantNotice,
  },
  {
    element: BroadcastMessageSearchPage,
    feature: FEATURE.Message,
  },
  {
    element: OrderDrawingSearchPage,
    feature: FEATURE.OngoingOrderDrawing,
  },
  {
    element: OrderDrawingSinglePage,
    feature: FEATURE.OngoingOrderDrawing,
    single: true,
  },
  {
    element: OrderDrawingSearchPage,
    feature: FEATURE.InactiveOrderDrawing,
  },
  {
    element: OrderDrawingSinglePage,
    feature: FEATURE.InactiveOrderDrawing,
    single: true,
  },
  {
    element: OrderOfferSearchPage,
    feature: FEATURE.OngoingOrderOffer,
  },
  {
    element: OrderDrawingSinglePage,
    feature: FEATURE.OngoingOrderOffer,
    single: true,
  },
  {
    element: OrderOfferSearchPage,
    feature: FEATURE.InactiveOrderOffer,
  },
  {
    element: OrderDrawingSinglePage,
    feature: FEATURE.InactiveOrderOffer,
    single: true,
  },
];
