import { RevisionUserFileAssociation } from '@/types/file/userFile';
import { RevisionCustomerFileAssociation } from '@/types/file/customerFile';
import {
  RevisionSubmitRequest,
  RevisionDisputeRequest,
  RevisionClaimRevisedFilesRequest,
  RevisionRemoveRevisedFilesRequest,
} from '@/types/revision';
import { API_PATH } from '@/config/features';
import { RevisionSearchItem, RevisionDetails, RevisionSearchParams, RevisionInitiateRequest } from '@/types/revision';
import { emptyApi as api, getTags } from '@/store/emptyApi';
import { SearchView } from '@/types/search';
import { RevisionRevisionReasonAssociation } from '@/types/revisionReason';
import { RevisionOwnFileAssociation } from '@/types/file/ownFile';
import { Ticket } from '@/types/ticket';
import { AICheck, PlagCheck } from '@/types/plagAICheck';

// Entity
const type = 'Revision';
const path = API_PATH[type];

const { providesTags, invalidatesTags } = getTags(type);

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchRevisions: build.query<SearchView<RevisionSearchItem>, RevisionSearchParams>({
      query: (params) => ({ url: `${path}`, params }),
      providesTags: [{ type, id: 'search' }],
    }),
    getRevision: build.query<RevisionDetails, number>({
      query: (id) => ({ url: `${path}/${id}` }),
      providesTags,
    }),
    initiateRevision: build.mutation<void, RevisionInitiateRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/initiate`, method: 'PATCH', body }),
      invalidatesTags,
    }),
    submitRevision: build.mutation<void, RevisionSubmitRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/submit`, method: 'PATCH', body }),
      invalidatesTags,
    }),
    disputeRevision: build.mutation<void, RevisionDisputeRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/dispute`, method: 'PATCH', body }),
      invalidatesTags,
    }),
    getRevisionTickets: build.query<Ticket[], number>({
      query: (id) => ({ url: `${path}/${id}/tickets` }),
      providesTags: (result, error, id) => [{ type, id: 'tickets' + id }, 'Ticket'],
    }),
    searchRevisionACustomerFiles: build.query<RevisionCustomerFileAssociation[], number>({
      query: (id) => ({ url: `${path}/${id}/a/customer-files` }),
      providesTags: (result, error, id) => [{ type, id: 'customerFiles' + id }],
    }),
    searchRevisionARevisionReasons: build.query<RevisionRevisionReasonAssociation[], number>({
      query: (id) => ({ url: `${path}/${id}/a/reasons` }),
      providesTags: (result, error, id) => [{ type, id: 'reasons' + id }],
    }),
    searchRevisionARevisedWriterFiles: build.query<RevisionOwnFileAssociation[], number>({
      query: (id) => ({ url: `${path}/${id}/a/revised-writer-files` }),
      providesTags: (result, error, id) => [{ type, id: 'revisedWriterFiles' + id }],
    }),
    claimRevisionARevisedWriterFiles: build.mutation<void, RevisionClaimRevisedFilesRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/a/revised-writer-files/claim`, method: 'PUT', body }),
      invalidatesTags: (result, error, { id }) => [{ type, id: 'revisedWriterFiles' + id }],
    }),
    removeRevisionARevisedWriterFiles: build.mutation<void, RevisionRemoveRevisedFilesRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/a/revised-writer-files/withdraw`, method: 'DELETE', body }),
      invalidatesTags: (result, error, { id }) => [{ type, id: 'revisedWriterFiles' + id }],
    }),
    searchRevisionAUserFiles: build.query<RevisionUserFileAssociation[], number>({
      query: (id) => ({ url: `${path}/${id}/a/user-files` }),
      providesTags: (result, error, id) => [{ type, id: 'userFiles' + id }],
    }),
    searchRevisionAOwnFiles: build.query<RevisionOwnFileAssociation[], number>({
      query: (id) => ({ url: `${path}/${id}/a/writer-files` }),
      providesTags: (result, error, id) => [{ type, id: 'writerFiles' + id }],
    }),

    searchRevisionPlagChecks: build.query<PlagCheck[], number>({
      query: (id) => ({ url: `${path}/${id}/plag-checks` }),
      providesTags: (result, error, id) => [{ type, id: 'plagChecks' + id }],
    }),
    searchRevisionAIChecks: build.query<AICheck[], number>({
      query: (id) => ({ url: `${path}/${id}/ai-checks` }),
      providesTags: (result, error, id) => [{ type, id: 'aiChecks' + id }],
    }),
  }),
  overrideExisting: false,
});

export { injectedRtkApi as enhancedApi };

export const {
  useSearchRevisionsQuery,
  useLazySearchRevisionsQuery,
  useGetRevisionQuery,
  useInitiateRevisionMutation,
  useSubmitRevisionMutation,
  useDisputeRevisionMutation,
  useSearchRevisionACustomerFilesQuery,
  useLazySearchRevisionACustomerFilesQuery,
  useSearchRevisionARevisionReasonsQuery,
  useSearchRevisionARevisedWriterFilesQuery,
  useLazySearchRevisionARevisedWriterFilesQuery,
  useClaimRevisionARevisedWriterFilesMutation,
  useRemoveRevisionARevisedWriterFilesMutation,
  useSearchRevisionAUserFilesQuery,
  useLazySearchRevisionAUserFilesQuery,
  useSearchRevisionAOwnFilesQuery,
  useLazySearchRevisionAOwnFilesQuery,
  useGetRevisionTicketsQuery,
  useLazyGetRevisionTicketsQuery,
  useSearchRevisionPlagChecksQuery,
  useLazySearchRevisionPlagChecksQuery,
  useSearchRevisionAIChecksQuery,
  useLazySearchRevisionAIChecksQuery,
} = injectedRtkApi;
