import { useViewCurrentUserProfileQuery } from '@/api/profile';
import { AppBaseLayout } from '@/components/common/layout/AppBaseLayout';
import { LazyPageHOC } from '@/components/common/LazyPageHOC';
import { SpinFullscreen } from '@/components/common/spinner/SpinFullscreen';
import { getAppRoutes } from '@/config/routes';
import { ReactElement, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ErrorPage } from '@/components/common/errors/ErrorPage';
import { PageDataLoader } from '@/components/common/layout/page/PageDataLoader';
import { BroadcastMessage } from '@/types/broadcastMessage';
import { BroadcastMessageContext } from '@feature/broadcastMessage/BroadcastMessageContext';
import { BroadcastMessageModal } from '@feature/broadcastMessage/BroadcastMessageModal';

export default function Dashboard(): ReactElement {
  const { data: profile, error } = useViewCurrentUserProfileQuery();

  const isAppReady = !!profile;

  const [currentBroadcast, setCurrentBroadcast] = useState<BroadcastMessage | null>(null);
  const [broadcastModalOpen, setBroadcastModalOpen] = useState(false);

  const setBroadcastMessage = (broadcastMessage: BroadcastMessage) => {
    setCurrentBroadcast(broadcastMessage);
    setBroadcastModalOpen(true);
  };

  const routes = getAppRoutes();

  return (
    <PageDataLoader data={profile} error={error} blockEmptyData>
      <BroadcastMessageContext.Provider
        value={{
          setBroadcastMessage,
        }}
      >
        <AppBaseLayout>
          {isAppReady ? (
            <Routes>
              {routes.map((props) => (
                <Route key={props.path} path={props.path} element={<LazyPageHOC {...props} />} />
              ))}
              <Route path="*" element={<ErrorPage code={404} />} />
            </Routes>
          ) : (
            <SpinFullscreen />
          )}
        </AppBaseLayout>
        <BroadcastMessageModal
          broadcastMessage={currentBroadcast}
          open={broadcastModalOpen}
          onPreviewClick={(message) => setCurrentBroadcast(message)}
          onClose={() => {
            setBroadcastModalOpen(false);
            setTimeout(() => {
              setCurrentBroadcast(null);
            }, 300);
          }}
        />
      </BroadcastMessageContext.Provider>
    </PageDataLoader>
  );
}
