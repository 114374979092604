import packageJson from '../../package.json';
import { range } from 'lodash';
import moment from 'moment-timezone';

// Sync with <title> from index.html
export const SITE_NAME = 'Writer';

export const IS_DEV = process.env.NODE_ENV === 'development';

// Track app version
export const APP_VERSION = packageJson.version; // show version

export const API_BASE_URL = process.env.REACT_APP_API_WRITER_URL || 'REACT_APP_API_WRITER_URL';

export const WRITER_LANDING_URL = process.env.REACT_APP_WRITER_LANDING_URL || 'WRITER_LANDING_URL';

// Test credentials.
// Will be removed before production!
export const testCredentials = {
  address: process.env.REACT_APP_EMAIL,
  password: process.env.REACT_APP_PASSWORD,
};

// DATE ISO FORMAT
export const DATE_FORMAT_ISO = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT_DEFAULT = 'MMM DD, YYYY hh:mm A';
export const DATE_FORMAT_SHORT = 'MMM DD HH:mm';
export const FRONT_TIME_FORMAT = 'hh:mm A';
export const SERVER_TIME_FORMAT = 'HH:mm:ss';
export const TIME_FORMAT = 'h:mm A';

export const ALLOWED_IMAGES = ['.jpeg', '.png', '.gif', '.jpg'];

export const ALLOWED_EXTENSIONS = [
  ...ALLOWED_IMAGES,
  '.txt',
  '.zip',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.rtf',
  '.ppt',
  '.pptx',
  '.csv',
  '.pdf',
  '.odt',
  '.html',
];

export const UPLOAD_ACCEPT_FILES = ALLOWED_EXTENSIONS.join(',');
export const UPLOAD_IMAGE_FILES = ALLOWED_IMAGES.join(',');

export const PAGE_SIZE_OPTIONS = range(1, 101);

export const isMac = !!navigator.platform.match(/mac/gi);

export const USER_DATA_KEY: 'customer' | 'writer' | 'author' = 'writer'; // for messenger

// The only difference between timezones on back-end and front-end
export const TIMEZONES = moment.tz.names().filter((tz) => !['Europe/Kyiv'].includes(tz));

export const PRIMARY_TIME_ZONES = [
  'EST5EDT',
  'CST6CDT',
  'MST7MDT',
  'PST8PDT',
  'MST',
  'Africa/Nairobi',
  'Europe/Berlin',
  'Europe/London',
  'Australia/Sydney',
];

export const COUNTER_INTERVAL_SECONDS = 45;

// https://chat.cloudsend.pro/app/accounts/2/settings/inboxes/21
export const CHATWOOT_TOKEN = '168QfjVZKaGTAgtLneMHugUe'; // TODO: Update token

export const SENTRY_DSN = 'https://f51bc2440d6a42aea5e6621639d25762@o1028159.ingest.sentry.io/4504646569230336';
