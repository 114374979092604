import { FA } from '@/components/common/icons/FA';
import { ButtonModifications } from '@/types/common/components';
import { FontAwesomeIcon, FontAwesomeThemes } from '@/types/common/fa';
import { Button as AntButton } from 'antd';
import { NativeButtonProps } from 'antd/lib/button/button';
import cn from 'classnames';

// Styles
import s from './Button.module.less';

export interface ButtonProps extends Omit<Partial<NativeButtonProps>, 'id'> {
  icon?: FontAwesomeIcon;
  theme?: FontAwesomeThemes;
  className?: string;
  circle?: boolean;
  modification?: ButtonModifications; // new! -> Button.module.less
}

export const Button: FC<ButtonProps> = ({ icon, children, loading, theme, modification, className, color, circle, ...props }) => {
  return (
    <AntButton
      loading={!icon && loading} // only if no icon
      icon={typeof icon === 'object' ? icon : null}
      className={cn(className, children ? s.hasChildren : s.iconOnly, {
        [s[`${props.size}`]]: props.size,
        [s[`${modification}`]]: !!modification,
        [s.loading]: !!loading, // loading mode
      })}
      shape={circle ? 'circle' : 'default'}
      {...props} // supports all antd button props
    >
      {/* Prefix */}
      {icon && (
        <FA
          type={loading ? 'fa-spinner-third spin-fast' : icon}
          style={{ color: !modification ? color : 'inherit' }}
          theme={theme || 'far'}
        />
      )}
      {/* Wrapped content */}
      {children}
    </AntButton>
  );
};
