import { useCallback, useMemo } from 'react';
import { Popover, Button } from 'antd';
import cn from 'classnames';

import { SearchDateFiltersForm, SearchDateFiltersFormProps } from './SearchDateFiltersForm';
import { GeneralSearchParams } from '@/shared/components/searchEngine/src/types';

type SearchDateFiltersProps<T extends GeneralSearchParams> = SearchDateFiltersFormProps<T>;

export const SearchDateFilters = <T extends GeneralSearchParams>(props: SearchDateFiltersProps<T>) => {
  const { keys, params, isPopover, dateRangeIcon } = props;
  const isDateParam = useCallback((param) => (keys as string[]).includes(param.replace(/(From|To)$/, '')), [keys]);

  const active = useMemo(() => Object.keys(params).find((param) => isDateParam(param)), [params, isDateParam]);

  return isPopover ? (
    <div className="core-search-engine-date-popover">
      <Popover trigger="click" placement="bottom" content={<SearchDateFiltersForm {...props} />}>
        <Button
          className={cn('ant-btn-square', 'core-search-engine-date-popover__button', {
            'core-search-engine-date-popover__button--active': active,
          })}
        >
          {dateRangeIcon}
        </Button>
      </Popover>
    </div>
  ) : (
    <SearchDateFiltersForm {...props} />
  );
};
