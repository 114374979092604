import { Patch } from '@/types/common/api';
import { LogMessage } from './common/api';
import { CommonSearchParams } from './search';
import { WriterOrder } from './writerOrder';
import { Order, OrderSnapshotCondensed } from './order';
import { RevisionMagnitude } from './revisionMagnitude';
import { User } from './user';
import { RevisionRevisionReasonAssociation } from './revisionReason';

// Actions
export type RevisionActions = 'initiate' | 'submit' | 'dispute';

export enum RevisionStatuses {
  Draft = 1,
  Claimed,
  Pending,
  InWork,
  Submitted,
  Completed,
  Canceled,
  Withdrawn,
  Disputed,
  Declined,
  Removed,
}

// Basic summary
export type Revision = {
  id: number;
  dateAdded: string;
  datePending?: string;
  dateDue?: string;
  dateWriterDue?: string;
  writerName?: string;
  writerDescription?: string;
  urgency?: string;
  writerUrgency?: string;
  idStatus: RevisionStatuses;
};

// Common
export type RevisionExtended = Revision;

// Single
export type RevisionDetails = RevisionSearchItem & {
  associationReasons: RevisionRevisionReasonAssociation[];
};

// Search engine
export type RevisionSearchItem = RevisionExtended & {
  magnitude: RevisionMagnitude;
  order: Order;
  originalSnapshot: OrderSnapshotCondensed;
  writerOrder: WriterOrder;
  author?: User;
};

export type RevisionSearchParams = {
  idFrom?: number;
  idTo?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  name?: string;
  idStatus?: RevisionStatuses[];
  idMagnitude?: number[];
  idOrder?: number[];
  idOriginalSnapshot?: number[];
  sortBy?: RevisionSortField;
} & CommonSearchParams;

export type RevisionSortField = 'id' | 'dateAdded' | 'name' | 'amount' | 'idStatus' | 'idMagnitude' | 'idOrder' | 'idOriginalSnapshot';
export type RevisionDateField = 'dateAdded';

// Main actions (API)
export type RevisionInitiatePayload = LogMessage;
export type RevisionInitiateRequest = Patch<RevisionInitiatePayload>;

export type RevisionSubmitPayload = LogMessage;
export type RevisionSubmitRequest = Patch<RevisionSubmitPayload>;

export type RevisionDisputePayload = LogMessage;
export type RevisionDisputeRequest = Patch<RevisionDisputePayload>;

// Associations
export enum OwnFileRevisionAssociationStatuses {
  Claimed = 1,
  Set,
  Confirmed,
  Removed,
}

export type OwnFileRevisionAssociation = {
  dateAdded: string;
  idStatus: OwnFileRevisionAssociationStatuses;
  revision: Revision;
};

export type RevisionClaimRevisedFilesPayload = {
  ids: number[];
};
export type RevisionClaimRevisedFilesRequest = Patch<RevisionClaimRevisedFilesPayload>;

export type RevisionRemoveRevisedFilesPayload = {
  ids: number[];
};
export type RevisionRemoveRevisedFilesRequest = Patch<RevisionRemoveRevisedFilesPayload>;
