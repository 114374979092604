import { WriterPass } from './writerPass';
import { Country } from './country';
import { Email, ProfileEmailAssociation } from './email';
import { Phone, ProfilePhoneAssociation } from './phone';
import { User } from '@/types/user';
import { WriterPayoutEmailExtended } from '@/types/writerPayoutEmail';

export enum WriterStatuses {
  Draft = 1,
  Active,
  Paused,
  Removed,
  New,
}

export type ProfilePreferences = {
  idPinnedTicket?: number;
  debugMode?: boolean;
  timezone?: string;
  avatar?: string;
  isCompactNavigation?: boolean;
};

export type ProfileInfo = {
  id: number;
  dateAdded: string;
  firstName?: string;
  lastName?: string;
  publicName?: string;
  preferences?: ProfilePreferences;
  doNotDisturbStart?: string;
  doNotDisturbEnd?: string;
  timezone?: string;
  idStatus: WriterStatuses;
  masterWriter?: User;
  writerPass?: WriterPass;
  subordinateWriters: User[];
  associationPhones: ProfilePhoneAssociation[];
  writerPayoutEmails: WriterPayoutEmailExtended[];
  associationEmails: ProfileEmailAssociation[];
  primaryEmail: Email;
  primaryPhone?: Phone;
  country?: Country;
};

export type ProfileCoolDownsResponse = {
  dateOrderDrawingOpens: string; // date unlock retry order drawing request
};

// ACTIONS

export type ChangeProfilePasswordPayload = {
  currentPassword: string;
  newPassword: string;
};

export type ChangeProfilePasswordRequest = ChangeProfilePasswordPayload;

export type ChangeProfileNamePayload = {
  firstName?: string;
  lastName?: string;
  publicName?: string;
};

export type ChangeProfileNameRequest = ChangeProfileNamePayload;

export type UpdateProfilePreferencesPayload = {
  preferences: ProfilePreferences;
};

export type UpdateProfilePreferencesRequest = UpdateProfilePreferencesPayload;

export type UpdateProfileTimezonePayload = {
  logMessage?: string;
  timezone: string;
};

export type UpdateProfileTimezoneRequest = UpdateProfileTimezonePayload;

export type UpdateProfileCountryPayload = {
  logMessage?: string;
  idCountry: string;
};

export type UpdateProfileCountryRequest = UpdateProfileCountryPayload;

export type UpdateProfileActiveHoursPayload = {
  logMessage?: string;
  doNotDisturbStart: string;
  doNotDisturbEnd: string;
};

export type UpdateProfileActiveHoursRequest = UpdateProfileActiveHoursPayload;

export type RemoveProfileWriterPassPayload = {
  logMessage: string;
};

export type RemoveProfileWriterPassRequest = RemoveProfileWriterPassPayload;

export type ClaimProfileAPhonePayload = {
  number: string;
  idCallingCode: string;
};

export type ClaimProfileAPhoneRequest = ClaimProfileAPhonePayload;

export type AbandonProfileAPhonePayload = {
  idPhone: number;
};

export type AbandonProfileAPhoneRequest = AbandonProfileAPhonePayload;

export type ClaimProfileAEmailPayload = {
  address: string;
};

export type ClaimProfileAEmailRequest = ClaimProfileAEmailPayload;

export type AbandonProfileAEmailPayload = {
  idEmail: number;
};

export type AbandonProfileAEmailRequest = AbandonProfileAEmailPayload;

export type AuthSignUpPayload = {
  email: string;
  firstName: string;
  lastName: string;
  publicName?: string;
  password: string;
  idCountry?: number;
  idCallingCode?: string;
  number?: string;
};

export type AuthSignUpRequest = AuthSignUpPayload;
