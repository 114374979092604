import { useViewCurrentUserProfileQuery } from '@/api/profile';
import { FA } from '@/components/common/icons/FA';
import { CustomLink } from '@/components/common/router/CustomLink';
import { useAppDispatch } from '@/hooks/redux';
import { t } from '@/services/translator';
import { resetSearchPagination } from '@/store/localPreferences/localPreferenceSlice';
import { Input } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import s from './Navigation.module.less';
import cn from 'classnames';
import { useGracefulLogout } from '@/store/auth/authHooks';
import { SITE_MENU, SiteMenuItem } from '@/config/features';
import { useBreakpoints } from '@/hooks/breakpoints/useBreakpoints';
import { WinSm } from '@/hooks/breakpoints/winSizes';
import { usePreferences } from '@/hooks/preferences';

type TNavigationProps = {
  setDrawerVisible: (value: boolean) => void;
};

export const Navigation: FC<TNavigationProps> = ({ setDrawerVisible }) => {
  const [filter, setFilter] = useState('');
  const { data: user } = useViewCurrentUserProfileQuery();
  const { logout } = useGracefulLogout();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { preferences } = usePreferences();

  const { isCompactNavigation } = preferences;
  const { isSmMin } = useBreakpoints();

  if (!user) return null;

  // // regEx - live filter feature!
  const regEx = new RegExp(filter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/\s/g, '.*?'), 'gi');

  const applyLiveFilter = ({ title }: SiteMenuItem) => t(title)?.match(regEx);

  const availableMenuItems = SITE_MENU.filter((item) => (item.renderCondition ? item.renderCondition(user) : true))
    .reduce((prev, next) => [...prev, ...next.items], [] as SiteMenuItem[])
    .filter((item) => (item.renderCondition ? item.renderCondition(user) : true));

  // // render only with order key
  const menuItems = filter?.trim() ? availableMenuItems.filter(applyLiveFilter) : availableMenuItems;

  const handleItemClick = () => {
    dispatch(resetSearchPagination());
    setDrawerVisible(false);
  };

  const menuGroups = SITE_MENU.filter(({ items }) => !!items.find((item) => menuItems.includes(item))).map(({ name, items }) => ({
    name,
    items: items.filter((item) => menuItems.includes(item)),
  }));

  return (
    <div className={cn(s.wrapper, { [s.compact]: isCompactNavigation })}>
      {((isSmMin && !isCompactNavigation) || !isSmMin) && (
        <div className={cn(s.item, s.itemSearch)} style={{ position: 'sticky', top: 5, zIndex: 1 }}>
          <Input.Search
            tabIndex={1}
            placeholder={t('misc.search')}
            allowClear
            onChange={(e) => setFilter(e.target.value)}
            style={{ width: '100%', marginRight: 0, transition: 'all 4s ease' }}
            onSearch={(searchVal) => {
              if (menuItems.length < 1 || !searchVal) return;

              const path = menuItems[0].path;

              if (path) {
                navigate(path);
              }
            }}
          />
        </div>
      )}

      {menuGroups.map(({ name, items }) => (
        <div className={s.menuGroupWrap} key={name}>
          <div className={s.menuGroupName}>{t(name)}</div>
          <ul className={s.menuGroup}>
            {items.map((item) => (
              <li key={item.path || item.title} className={s.item}>
                <CustomLink to={String(item.path)} onClick={handleItemClick} className={s.link} activeClassName={s.active} tabIndex={1}>
                  {item.icon && <FA type={item.icon} className={s.icon} />}
                  <span className={s.text}>{t(item.title)}</span>
                </CustomLink>
              </li>
            ))}
          </ul>
        </div>
      ))}

      <WinSm>
        <div className={s.item}>
          <div
            className={s.link}
            onClick={() => {
              setDrawerVisible(false);
              logout();
            }}
          >
            <FA type="fa-sign-out-alt" text={t('profile.logOut')} />
          </div>
        </div>
        <div className={s.item}>
          <div
            className={s.link}
            onClick={() => {
              setDrawerVisible(false);
              logout(true);
            }}
          >
            <FA type="fa-right-from-bracket" theme="fad" text={t('profile.logOutGlobal')} />
          </div>
        </div>
      </WinSm>
    </div>
  );
};
