import { FontAwesomeIcon } from '@/types/common/fa';
import { ProfileInfo, WriterStatuses } from '@/types/profile';
export type Feature = {
  title: string;
  path: `/${string}`;
  icon?: FontAwesomeIcon;
  titleSingle?: string;
};

export type ApiTag = typeof API_TAG_LIST[number];

export const API_TAG_LIST = [
  'Dicts',
  'Login',
  'Profile',
  'Fintech',
  'FieldOfStudy',
  'Grading',
  'Revision',
  'Ticket',
  'WriterSkill',
  'WriterPass',
  'OwnFile',
  'AvailableOrder',
  'WriterPayment',
  'WriterPaymentBatch',
  'WriterManual',
  'WriterManualTag',
  'Feedback',
  'WriterOrder',
  'WriterFieldCompetence',
  'WriterSkillCompetence',
  'BroadcastMessage',
  'OrderDrawing',
  'WriterPayoutEmail',
] as const;

export const API_PATH: {
  [key in ApiTag]: `/${string}`;
} = {
  Dicts: '/dicts',
  Fintech: '/fintechs',
  Login: '/login',
  FieldOfStudy: '/fields-of-study',
  Grading: '/gradings',
  Profile: '/profile',
  Revision: '/revisions',
  Ticket: '/tickets',
  WriterSkill: '/writer-skills',
  WriterPass: '/writer-passes',
  WriterPayment: '/writer-payments',
  WriterPaymentBatch: '/writer-payment-batches',
  Feedback: '/feedbacks',
  OwnFile: '/own-files',
  AvailableOrder: '/available-orders',
  WriterOrder: '/writer-orders',
  WriterManual: '/writer-manuals',
  WriterManualTag: '/writer-manual-tags',
  WriterFieldCompetence: '/writer-field-competences',
  WriterSkillCompetence: '/writer-skill-competences',
  BroadcastMessage: '/broadcast-messages',
  OrderDrawing: '/order-drawings',
  WriterPayoutEmail: '/writer-payout-emails',
};

export const FEATURE_LIST = [
  'Profile',
  'WriterPass',
  'ActiveWriterOrder',
  'InactiveWriterOrder',
  'AvailableOrder',
  'ClaimedWriterOrder',
  'Revision',
  'Grading',
  'WriterPayment',
  'WriterPaymentBatch',
  'WriterFieldCompetence',
  'Ticket',
  'FAQ',
  'Terms',
  'Wiki',
  'KnowledgeBase',
  'PlagCheck',
  'AICheck',
  'Notifications',
  'ImportantNotice',
  'Message',
  'OngoingOrderDrawing',
  'InactiveOrderDrawing',
  'OngoingOrderOffer',
  'InactiveOrderOffer',
] as const;

export type FeatureType = typeof FEATURE_LIST[number];

export const FEATURE: { [key in FeatureType]: Feature } = {
  Profile: {
    title: 'profile.profile',
    path: '/',
  },
  WriterPass: {
    icon: 'fa-file-certificate',
    title: 'writerPasses.namePl',
    titleSingle: 'writerPasses.name',
    path: API_PATH.WriterPass,
  },
  ActiveWriterOrder: {
    icon: 'fa-file-user',
    title: 'order.writerOrders',
    titleSingle: 'order.order',
    path: '/my-orders',
  },
  InactiveWriterOrder: {
    icon: 'fa-file-user',
    title: 'order.inactiveOrders',
    titleSingle: 'order.order',
    path: '/inactive-orders',
  },
  AvailableOrder: {
    icon: 'fa-file-pen',
    title: 'order.availableOrders',
    titleSingle: 'order.order',
    path: API_PATH.AvailableOrder,
  },
  ClaimedWriterOrder: {
    icon: 'fa-file-pen',
    title: 'order.claimedOrders',
    titleSingle: 'order.order',
    path: '/claimed-orders',
  },
  PlagCheck: {
    title: 'plagChecks.plagCheck',
    path: '/:entity/:idEntity/plag-checks',
  },
  AICheck: {
    title: 'aiChecks.aiCheck',
    path: '/:entity/:idEntity/ai-checks',
  },
  Revision: {
    icon: 'fa-arrows-repeat',
    title: 'revisions.revisions',
    titleSingle: 'revisions.revision',
    path: API_PATH.Revision,
  },
  Grading: {
    icon: 'fa-chevron-double-down',
    title: 'gradings.gradings',
    titleSingle: 'gradings.grading',
    path: API_PATH.Grading,
  },
  WriterPayment: {
    icon: 'fa-money-check-dollar-pen',
    title: 'writerPayments.writerPayments',
    path: '/payments',
  },
  WriterPaymentBatch: {
    icon: 'fa-file-invoice-dollar',
    title: 'writerPaymentBatches.writerPaymentBatches',
    titleSingle: 'writerPaymentBatches.writerPaymentBatch',
    path: '/payment-batches',
  },
  Ticket: {
    icon: 'fa-ticket',
    title: 'tickets.tickets',
    titleSingle: 'tickets.ticket',
    path: API_PATH.Ticket,
  },
  WriterFieldCompetence: {
    icon: 'fa-user-check',
    title: 'fieldCompetences.namePl',
    titleSingle: 'fieldCompetences.name',
    path: '/field-competences',
  },
  FAQ: {
    icon: 'fa-circle-info',
    title: 'misc.faqFull',
    path: '/faq',
  },
  Terms: {
    icon: 'fa-file-exclamation',
    title: 'misc.termsAndConditions',
    path: '/terms-and-conditions',
  },
  Wiki: {
    icon: 'fa-book',
    title: 'misc.wikiForWriters',
    path: '/wiki-for-writers',
  },
  KnowledgeBase: {
    icon: 'fa-books',
    title: 'misc.knowledgeBase',
    path: '/knowledge-base',
  },
  Notifications: {
    icon: 'fa-bell-exclamation',
    title: 'misc.notifications',
    path: '/notifications',
  },
  ImportantNotice: {
    icon: 'fa-message-exclamation',
    title: 'misc.importantNotices',
    path: '/important-notices',
  },
  Message: {
    icon: 'fa-message-lines',
    title: 'misc.messages',
    path: '/messages',
  },
  OngoingOrderDrawing: {
    icon: 'fa-file-circle-plus',
    title: 'orderDrawing.orderDrawings',
    titleSingle: 'orderDrawing.orderDrawing',
    path: '/order-drawings',
  },
  InactiveOrderDrawing: {
    icon: 'fa-file-circle-plus',
    title: 'orderDrawing.inactiveOrderDrawings',
    titleSingle: 'orderDrawing.orderDrawing',
    path: '/inactive-drawings',
  },
  OngoingOrderOffer: {
    icon: 'fa-file-signature',
    title: 'orderDrawing.orderOffers',
    titleSingle: 'orderDrawing.orderOffer',
    path: '/order-offers',
  },
  InactiveOrderOffer: {
    icon: 'fa-file-signature',
    title: 'orderDrawing.inactiveOrderOffers',
    titleSingle: 'orderDrawing.orderOffer',
    path: '/inactive-offers',
  },
};

export type SiteMenuItem = {
  title: string;
  path?: string;
  icon: FontAwesomeIcon | undefined;
  renderCondition?: (info: ProfileInfo) => boolean;
};

export type SiteMenuGroup = {
  name: string;
  items: SiteMenuItem[];
  renderCondition?: (info: ProfileInfo) => boolean;
};

const isUserNotNew = (info: ProfileInfo) => info.idStatus !== WriterStatuses.New;

export const SITE_MENU: SiteMenuGroup[] = [
  {
    name: 'menu.main',
    renderCondition: isUserNotNew,
    items: [
      {
        title: FEATURE.ActiveWriterOrder.title,
        path: FEATURE.ActiveWriterOrder.path,
        icon: FEATURE.ActiveWriterOrder.icon,
        renderCondition: isUserNotNew,
      },
      {
        title: FEATURE.OngoingOrderDrawing.title,
        path: FEATURE.OngoingOrderDrawing.path,
        icon: FEATURE.OngoingOrderDrawing.icon,
        renderCondition: isUserNotNew,
      },
      {
        title: FEATURE.OngoingOrderOffer.title,
        path: FEATURE.OngoingOrderOffer.path,
        icon: FEATURE.OngoingOrderOffer.icon,
        renderCondition: isUserNotNew,
      },
      {
        title: FEATURE.AvailableOrder.title,
        path: FEATURE.AvailableOrder.path,
        icon: FEATURE.AvailableOrder.icon,
        renderCondition: isUserNotNew,
      },
      {
        title: FEATURE.Revision.title,
        path: FEATURE.Revision.path,
        icon: FEATURE.Revision.icon,
        renderCondition: isUserNotNew,
      },
      {
        title: FEATURE.Grading.title,
        path: FEATURE.Grading.path,
        icon: FEATURE.Grading.icon,
        renderCondition: isUserNotNew,
      },
      {
        title: FEATURE.Ticket.title,
        path: FEATURE.Ticket.path,
        icon: FEATURE.Ticket.icon,
        renderCondition: isUserNotNew,
      },
    ],
  },
  {
    name: 'menu.payments',
    renderCondition: isUserNotNew,
    items: [
      {
        title: FEATURE.WriterPaymentBatch.title,
        path: FEATURE.Profile.path + '?tab=payment-batches',
        icon: FEATURE.WriterPaymentBatch.icon,
        renderCondition: isUserNotNew,
      },
      {
        title: 'writerPayments.realBalance',
        path: FEATURE.Profile.path + '?tab=payments&wPaymentBalance=real',
        icon: FEATURE.WriterPayment.icon,
        renderCondition: isUserNotNew,
      },
      {
        title: 'writerPayments.pendingBalance',
        path: FEATURE.Profile.path + '?tab=payments&wPaymentBalance=pending',
        icon: FEATURE.WriterPayment.icon,
        renderCondition: isUserNotNew,
      },
    ],
  },
  {
    name: 'menu.broadcast',
    renderCondition: isUserNotNew,
    items: [
      {
        title: FEATURE.ImportantNotice.title,
        path: FEATURE.ImportantNotice.path,
        icon: FEATURE.ImportantNotice.icon,
        renderCondition: isUserNotNew,
      },
      {
        title: FEATURE.Message.title,
        path: FEATURE.Message.path,
        icon: FEATURE.Message.icon,
        renderCondition: isUserNotNew,
      },
    ],
  },
  {
    name: 'menu.info',
    items: [
      {
        title: 'misc.faqShort',
        path: FEATURE.FAQ.path,
        icon: FEATURE.FAQ.icon,
        renderCondition: isUserNotNew,
      },
      {
        title: 'misc.wikiForWriters',
        path: FEATURE.Wiki.path,
        icon: FEATURE.Wiki.icon,
      },
      {
        title: 'misc.knowledgeBase',
        path: FEATURE.KnowledgeBase.path,
        icon: FEATURE.KnowledgeBase.icon,
      },
      {
        title: 'misc.termsAndConditionsMenu',
        path: FEATURE.Terms.path,
        icon: FEATURE.Terms.icon,
      },
    ],
  },
];

export const getFeatureURL = (feature: FeatureType, suffix?: string | number): string => {
  return `${FEATURE[feature].path}${suffix ? `/${suffix}` : ''}`;
};
