import { Modal } from '@/components/common/Modal';
import { BroadcastMessage } from '@/types/broadcastMessage';
import s from './BroadcastMessageModal.module.less';
import { Space } from 'antd';
import { BroadcastMessageCategoryName } from '@feature/broadcastMessage/BroadcastMessageCategoryName';
import { BroadcastMessageHistoricTag } from '@feature/broadcastMessage/BroadcastMessageHistoricTag';
import { RichText } from '@/shared/components/RichText';
import { useLazyGetBroadcastMessageQuery, useMarkBroadcastMessagesAsReadMutation } from '@/api/broadcastMessages';
import { useEffect } from 'react';
import { BroadcastMessageRelatedPreview } from '@feature/broadcastMessage/BroadcastMessageRelatedPreview';
import { t } from '@/services/translator';
import { Button } from '@/components/common/button/Button';

type BroadcastMessageModalProps = {
  broadcastMessage: BroadcastMessage | null;
  open: boolean;
  onClose: () => void;
  onPreviewClick: (message: BroadcastMessage) => void;
};

export const BroadcastMessageModal = ({ broadcastMessage, open, onClose, onPreviewClick }: BroadcastMessageModalProps) => {
  const [getDetails, { data }] = useLazyGetBroadcastMessageQuery();
  const [markAsRead] = useMarkBroadcastMessagesAsReadMutation();

  const idMessage = broadcastMessage?.id;

  const handleActionButtonClick = () => {
    if (window['$zopim'] !== undefined && window['$zopim'].livechat !== undefined) {
      window['$zopim'].livechat.say(`${t('misc.broadCastQuestion')} ${broadcastMessage?.writerName}`);
    }
  };

  useEffect(() => {
    if (idMessage) {
      getDetails(idMessage, true);
    }
  }, [getDetails, idMessage]);

  useEffect(() => {
    if (idMessage && data && idMessage === data.id && !data.isRead) {
      markAsRead({ ids: [idMessage] });
    }
  }, [markAsRead, idMessage, data]);

  if (!broadcastMessage) return null;

  const relatedMessages = [data?.parentMessage, ...(data?.childrenMessages || [])].filter(Boolean) as BroadcastMessage[];

  return (
    <Modal
      title={
        <div className={s.header}>
          <div className={s.title}>
            {broadcastMessage.isHistoric && (
              <span className={s.historicMark}>
                <BroadcastMessageHistoricTag />
              </span>
            )}
            {broadcastMessage.writerName}
          </div>
          <Space size={4}>
            <BroadcastMessageCategoryName id={broadcastMessage.idCategory} tag />
          </Space>
        </div>
      }
      width={500}
      open={open}
      onCancel={onClose}
      zIndex={1100}
      footer={
        <>
          {relatedMessages.length ? (
            <div className={s.relatedWrap}>
              <div className={s.relatedTitle}>{t('misc.relatedMessages')}</div>
              <div className={s.related}>
                {relatedMessages.map((message) => (
                  <BroadcastMessageRelatedPreview key={message.id} broadcastMessage={message} onClick={onPreviewClick} />
                ))}
              </div>
            </div>
          ) : null}
          <div className={s.askBtnWrap}>
            <Button onClick={handleActionButtonClick}>{t('misc.askQuestion')}</Button>
          </div>
        </>
      }
    >
      <RichText text={broadcastMessage.writerDescription} />
    </Modal>
  );
};
