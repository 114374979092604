import { API_PATH } from '@/config/features';
import { setUser } from '@/store/auth/authSlice';
import { emptyApi as api, getTags } from '@/store/emptyApi';
import { ProfileEmailAssociation } from '@/types/email';
import { ProfilePhoneAssociation } from '@/types/phone';
import {
  AbandonProfileAEmailRequest,
  AbandonProfileAPhoneRequest,
  ChangeProfileNameRequest,
  ChangeProfilePasswordRequest,
  ClaimProfileAEmailRequest,
  ClaimProfileAPhoneRequest,
  ProfileCoolDownsResponse,
  ProfileInfo,
  UpdateProfileActiveHoursRequest,
  UpdateProfileCountryRequest,
  UpdateProfilePreferencesRequest,
  UpdateProfileTimezoneRequest,
} from '@/types/profile';
import { WriterFieldCompetenceDetails } from '@/types/writerFieldCompetence';

// Entity
const type = 'Profile';
const path = API_PATH[type];

const { invalidatesTags } = getTags(type);

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    viewCurrentUserProfile: build.query<ProfileInfo, void>({
      providesTags: [{ type }],
      extraOptions: { customErrorHandler: true },
      query: () => ({
        url: path,
      }),
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;

        dispatch(setUser(data)); // sync (messenger required)
      },
    }),
    changeProfilePassword: build.mutation<void, ChangeProfilePasswordRequest>({
      query: (body) => ({
        url: `${path}/password`,
        method: 'PATCH',
        body,
      }),
    }),
    changeProfileName: build.mutation<void, ChangeProfileNameRequest>({
      query: (body) => ({
        url: `${path}/name`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags,
    }),
    updateProfilePreferences: build.mutation<void, UpdateProfilePreferencesRequest>({
      query: (body) => ({ url: `${path}/preferences`, method: 'PATCH', body }),
      onQueryStarted: async (arg, { dispatch }) => {
        dispatch(
          injectedRtkApi.util.updateQueryData('viewCurrentUserProfile', undefined, (draft) => {
            draft.preferences = arg.preferences;
          }),
        );
      },
    }),
    updateProfileTimezone: build.mutation<void, UpdateProfileTimezoneRequest>({
      query: (body) => ({ url: `${path}/timezone`, method: 'PATCH', body }),
      invalidatesTags,
    }),
    updateProfileCountry: build.mutation<void, UpdateProfileCountryRequest>({
      query: (body) => ({ url: `${path}/country`, method: 'PATCH', body }),
      invalidatesTags,
    }),
    updateProfileActiveHours: build.mutation<void, UpdateProfileActiveHoursRequest>({
      query: (body) => ({ url: `${path}/do-not-disturb`, method: 'PATCH', body }),
      invalidatesTags,
    }),

    getProfileAPhones: build.query<ProfilePhoneAssociation[], void>({
      query: () => ({ url: `${path}/a/phones` }),
      providesTags: () => [{ type, id: 'aPhones' }],
    }),
    claimProfileAPhone: build.mutation<ProfilePhoneAssociation, ClaimProfileAPhoneRequest>({
      query: (body) => ({ url: `${path}/a/phones/claim`, method: 'PUT', body }),
      invalidatesTags: () => [{ type, id: 'aPhones' }],
    }),
    abandonProfileAPhone: build.mutation<ProfilePhoneAssociation, AbandonProfileAPhoneRequest>({
      query: (body) => ({ url: `${path}/a/phones/abandon`, method: 'DELETE', body }),
      invalidatesTags: () => [{ type, id: 'aPhones' }],
    }),

    getProfileAEmails: build.query<ProfileEmailAssociation[], void>({
      query: () => ({ url: `${path}/a/emails` }),
      providesTags: () => [{ type, id: 'aEmails' }],
    }),
    claimProfileAEmail: build.mutation<ProfileEmailAssociation, ClaimProfileAEmailRequest>({
      query: (body) => ({ url: `${path}/a/emails/claim`, method: 'PUT', body }),
      invalidatesTags: () => [{ type, id: 'aEmails' }],
    }),
    abandonProfileAEmail: build.mutation<ProfileEmailAssociation, AbandonProfileAEmailRequest>({
      query: (body) => ({ url: `${path}/a/emails/abandon`, method: 'DELETE', body }),
      invalidatesTags: () => [{ type, id: 'aEmails' }],
    }),

    getProfileWriterFiledCompetences: build.query<WriterFieldCompetenceDetails[], void>({
      query: () => ({ url: `${path}/field-competences` }),
      providesTags: [{ type: 'Profile', id: 'fieldCompetences' }, 'WriterFieldCompetence'],
    }),

    /** Throttle some requests with cooldowns -> requires sync with API
     * [!] BIG NOTE: Use onQueryStarted (from search query) to invalidate cooldowns after each request
     * @example
     *  searchEndpoint: query<Resp, Req>({
     *    query,
     *    providesTags,
     *    onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
     *      await queryFulfilled;
     *      dispatch(emptyApi.util.invalidateTags([{ type: 'Profile', id: 'cooldowns' }])); <- invalidate cooldowns after each request
     *    },
     *  }),
     **/
    getProfileCoolDowns: build.query<ProfileCoolDownsResponse, void>({
      query: () => ({ url: `${path}/cooldowns` }),
      providesTags: [{ type: 'Profile', id: 'cooldowns' }],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };

export const {
  useViewCurrentUserProfileQuery,
  useLazyViewCurrentUserProfileQuery,
  useChangeProfilePasswordMutation,
  useChangeProfileNameMutation,
  useUpdateProfilePreferencesMutation,
  useUpdateProfileActiveHoursMutation,
  useUpdateProfileCountryMutation,
  useUpdateProfileTimezoneMutation,

  useGetProfileAEmailsQuery,
  useClaimProfileAEmailMutation,
  useAbandonProfileAEmailMutation,

  useGetProfileAPhonesQuery,
  useClaimProfileAPhoneMutation,
  useAbandonProfileAPhoneMutation,

  useGetProfileWriterFiledCompetencesQuery,

  useGetProfileCoolDownsQuery,
} = injectedRtkApi;
