import { setTokens } from '@/store/auth/authSlice';
import { emptyApi as api } from '@/store/emptyApi';
import { AuthLoginRequest, IAccessToken, ITokenPair } from '@/types/common/auth';
import { AuthRecoverPasswordRequest, AuthRequestRecoverPasswordRequest } from '@/shared/types/auth';
import { AuthSignUpRequest } from '@/types/profile';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    authLogin: build.mutation<ITokenPair, AuthLoginRequest>({
      query: (body) => ({
        url: `/auth/login`,
        method: 'POST',
        body,
      }),
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        dispatch(setTokens(data));
      },
    }),
    authRefresh: build.mutation<IAccessToken, void>({
      query: () => ({
        url: `/auth/refresh`,
        method: 'POST',
      }),
    }),
    authLogout: build.mutation<unknown, void>({
      query: () => ({
        url: `/auth/logout`,
        method: 'POST',
      }),
    }),
    authGlobalLogout: build.mutation<unknown, void>({
      query: () => ({
        url: `/auth/global-logout`,
        method: 'POST',
      }),
    }),
    authRecoverPassword: build.mutation<ITokenPair, AuthRecoverPasswordRequest>({
      query: (body) => ({
        url: `/auth/magic-password-recovery`,
        method: 'POST',
        body,
      }),
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        dispatch(setTokens(data));
      },
      extraOptions: {
        customErrorHandler: true,
      },
    }),
    authRequestRecoverPassword: build.mutation<void, AuthRequestRecoverPasswordRequest>({
      query: (body) => ({
        url: `/auth/magic-password-recovery/request`,
        method: 'POST',
        body,
      }),
    }),
    authSignUp: build.mutation<ITokenPair, AuthSignUpRequest>({
      query: (body) => ({
        url: `/auth/sign-up`,
        method: 'POST',
        body,
      }),
      extraOptions: {
        customErrorHandler: true,
      },
    }),
  }),
  overrideExisting: false,
});

export { injectedRtkApi as enhancedApi };

export const {
  useAuthLoginMutation,
  useAuthRefreshMutation,
  useAuthLogoutMutation,
  useAuthGlobalLogoutMutation,
  useAuthRequestRecoverPasswordMutation,
  useAuthRecoverPasswordMutation,
  useAuthSignUpMutation,
} = injectedRtkApi;
