import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import moment, { Moment } from 'moment';
import { ReactNode } from 'react';
import { LabelsResolver } from '../../helpers';

const { RangePicker } = DatePicker;

type MomentValue = Moment | null;
type MomentTuple = [MomentValue, MomentValue] | null;

export type SearchDateRangeProps = {
  value?: MomentTuple;
  onChange?: (value: MomentTuple) => void;
  picker?: RangePickerProps<moment.Moment>;
  closeDatePickerIcon: ReactNode;
  dateRangeIcon: ReactNode;
  t: LabelsResolver;
};

export const SearchDateRange = ({ value, onChange, picker, closeDatePickerIcon, dateRangeIcon, t }: SearchDateRangeProps) => {
  // Picker
  const onSelectionChange = (newValue: MomentTuple) => {
    onChange?.(newValue);
  };

  const hasValue = value?.find((value) => !!value);

  return (
    <div className="SearchDateRange">
      <RangePicker
        picker="date"
        format="DD.MM.YYYY"
        placeholder={[t.resolve('from'), t.resolve('to')]}
        defaultPickerValue={[moment().utc().add(-1, 'month'), moment()]}
        ranges={{
          'Last month': [moment().utc().add(-1, 'month'), moment.utc()],
          'Last week': [moment().utc().add(-1, 'week'), moment.utc()],
          /* eslint-disable */
          Today: [moment.utc().startOf('day'), moment.utc().endOf('day')],
        }}
        allowClear
        allowEmpty={[true, true]}
        onChange={onSelectionChange}
        value={value}
        suffixIcon={hasValue ? closeDatePickerIcon : dateRangeIcon}
        dropdownClassName="SearchDateRangeDropdown"
        {...picker}
      />
    </div>
  );
};
