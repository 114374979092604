import {
  WriterFieldCompetenceSearchParams,
  WriterFieldCompetenceDateField,
  WriterFieldCompetenceSortField,
} from '@/types/writerFieldCompetence';
import { WriterOrderDateField, WriterOrderSearchParams, WriterOrderSortField } from '@/types/writerOrder';
import { FeedbackDateField, FeedbackSearchParams, FeedbackSortField } from '@/types/feedback';
import { WriterPaymentBatchDateField, WriterPaymentBatchSortField } from '@/types/writerPaymentBatch';
import { WriterPaymentDateField, WriterPaymentSearchParams, WriterPaymentSortField } from '@/types/writerPayment';
import { TicketDateField, TicketSearchParams, TicketSortField } from '@/types/ticket';
import { GradingDateField, GradingSearchParams, GradingSortField } from '@/types/grading';
import { OwnFileDateField, OwnFileSearchParams, OwnFileSortField } from '@/types/file/ownFile';
import { RevisionDateField, RevisionSearchParams, RevisionSortField, RevisionStatuses } from '@/types/revision';
import { WriterPassDateField, WriterPassSearchParams, WriterPassSortField } from '@/types/writerPass';
import { AvailableOrderDateField, AvailableOrderSearchParams, AvailableOrderSortField } from '@/types/availableOrder';
import { WriterPaymentBatchSearchParams } from '@/types/writerPaymentBatch';
import { SearchEngineViewConfig } from '@/shared/components/searchEngine';
import { BroadcastMessageDateField, BroadcastMessageSearchParams, BroadcastMessageSortField } from '@/types/broadcastMessage';
import { OrderDrawingDateField, OrderDrawingSearchParams, OrderDrawingSortField } from '@/types/orderDrawing';

export const views = [
  'availableOrders',
  'revisions',
  'revisionsOrder',
  'ownFiles',
  'writerPasses',
  'gradings',
  'gradingsOrder',
  'tickets',
  'writerPaymentBalance',
  'writerPaymentBatches',
  'fieldCompetences',
  'feedbacks',
  'writerOrders',
  'notificationsOrders',
  'notificationsRevisions',
  'notificationsPopoverOrders',
  'notificationsPopoverRevisions',
  'notificationsBroadcastMessages',
  'notificationsPopoverBroadcastMessages',
  'notificationsHistoricBroadcastMessages',
  'notificationsPopoverHistoricBroadcastMessages',
  'broadcastMessages',
  'orderDrawings',
  'orderOffers',
] as const;

export type SearchEngineView = typeof views[number];

export const writerOrdersView: SearchEngineViewConfig<
  WriterOrderSearchParams,
  WriterOrderDateField,
  WriterOrderSortField,
  SearchEngineView
> = {
  id: 'writerOrders',
  defaultParams: {
    sortBy: 'dateAdded',
    sortType: 'desc',
    size: 10,
  },
  dateFields: ['dateAdded'],
  sortFields: ['dateAdded'],
  protectedFields: [],
};

export const availableOrdersView: SearchEngineViewConfig<
  AvailableOrderSearchParams,
  AvailableOrderDateField,
  AvailableOrderSortField,
  SearchEngineView
> = {
  id: 'availableOrders',
  defaultParams: {
    sortBy: 'dateAdded',
    sortType: 'desc',
    size: 10,
  },
  dateFields: ['dateAdded'],
  sortFields: ['dateAdded', 'id'],
  protectedFields: [],
};

export const revisionsView: SearchEngineViewConfig<RevisionSearchParams, RevisionDateField, RevisionSortField, SearchEngineView> = {
  id: 'revisions',
  defaultParams: {
    idStatus: [RevisionStatuses.InWork, RevisionStatuses.Pending],
    sortBy: 'dateAdded',
    sortType: 'desc',
    size: 10,
  },
  dateFields: ['dateAdded'],
  sortFields: ['dateAdded', 'id'],
  protectedFields: [],
};

export const revisionsOrderView: SearchEngineViewConfig<RevisionSearchParams, RevisionDateField, RevisionSortField, SearchEngineView> = {
  id: 'revisionsOrder',
  defaultParams: {
    sortBy: 'dateAdded',
    sortType: 'desc',
    size: 10,
  },
  dateFields: [],
  sortFields: [],
  protectedFields: ['idOrder'],
};

export const ownFilesView: SearchEngineViewConfig<OwnFileSearchParams, OwnFileDateField, OwnFileSortField, SearchEngineView> = {
  id: 'ownFiles',
  defaultParams: {
    sortBy: 'dateAdded',
    sortType: 'desc',
    size: 10,
  },
  dateFields: [],
  sortFields: [],
  protectedFields: [],
};

export const writerPassesView: SearchEngineViewConfig<WriterPassSearchParams, WriterPassDateField, WriterPassSortField, SearchEngineView> =
  {
    id: 'writerPasses',
    defaultParams: {
      sortBy: 'dateAdded',
      sortType: 'desc',
      size: 10,
    },
    dateFields: [],
    sortFields: [],
    protectedFields: [],
  };

export const gradingsView: SearchEngineViewConfig<GradingSearchParams, GradingDateField, GradingSortField, SearchEngineView> = {
  id: 'gradings',
  defaultParams: {
    sortBy: 'dateAdded',
    sortType: 'desc',
    size: 10,
  },
  dateFields: ['dateAdded'],
  sortFields: ['dateAdded', 'id'],
  protectedFields: [],
};

export const gradingsOrderView: SearchEngineViewConfig<GradingSearchParams, GradingDateField, GradingSortField, SearchEngineView> = {
  ...gradingsView,
  id: 'gradingsOrder',
  protectedFields: ['idOrder'],
};

export const ticketsView: SearchEngineViewConfig<TicketSearchParams, TicketDateField, TicketSortField, SearchEngineView> = {
  id: 'tickets',
  defaultParams: {
    sortBy: 'dateAdded',
    sortType: 'desc',
    size: 10,
  },
  dateFields: ['dateAdded', 'dateDue'],
  sortFields: ['dateAdded'],
  protectedFields: [],
};

export const writerPaymentBalanceView: SearchEngineViewConfig<
  WriterPaymentSearchParams,
  WriterPaymentDateField,
  WriterPaymentSortField,
  SearchEngineView
> = {
  id: 'writerPaymentBalance',
  defaultParams: {
    sortBy: 'dateAdded',
    sortType: 'desc',
    size: 10,
  },
  dateFields: ['dateAdded', 'datePending', 'dateExecuted'],
  sortFields: ['dateAdded'],
  protectedFields: ['idStatus'],
};

export const writerPaymentBatchesView: SearchEngineViewConfig<
  WriterPaymentBatchSearchParams,
  WriterPaymentBatchDateField,
  WriterPaymentBatchSortField,
  SearchEngineView
> = {
  id: 'writerPaymentBatches',
  defaultParams: {
    sortBy: 'dateAdded',
    sortType: 'desc',
    size: 10,
  },
  dateFields: ['dateAdded', 'dateExecuted'],
  sortFields: ['dateAdded'],
  protectedFields: [],
};

export const feedbacksView: SearchEngineViewConfig<FeedbackSearchParams, FeedbackDateField, FeedbackSortField, SearchEngineView> = {
  id: 'feedbacks',
  defaultParams: {
    sortBy: 'dateAdded',
    sortType: 'desc',
    size: 10,
  },
  dateFields: ['dateAdded'],
  sortFields: ['dateAdded'],
  protectedFields: [],
};

export const fieldCompetencesView: SearchEngineViewConfig<
  WriterFieldCompetenceSearchParams,
  WriterFieldCompetenceDateField,
  WriterFieldCompetenceSortField,
  SearchEngineView
> = {
  id: 'fieldCompetences',
  defaultParams: {
    sortBy: 'dateAdded',
    sortType: 'desc',
    size: 10,
  },
  dateFields: ['dateAdded', 'dateUpdated'],
  sortFields: ['dateAdded', 'dateUpdated'],
  protectedFields: [],
};

export const notificationsOrdersView: SearchEngineViewConfig<
  WriterOrderSearchParams,
  WriterOrderDateField,
  WriterOrderSortField,
  SearchEngineView
> = {
  id: 'notificationsOrders',
  defaultParams: {
    sortBy: 'dateAdded',
    sortType: 'desc',
    size: 20,
  },
  dateFields: ['dateAdded'],
  sortFields: ['dateAdded'],
  protectedFields: ['idStatus'],
};

export const notificationsOrdersPopoverView: SearchEngineViewConfig<
  WriterOrderSearchParams,
  WriterOrderDateField,
  WriterOrderSortField,
  SearchEngineView
> = {
  id: 'notificationsPopoverOrders' as const,
  defaultParams: {
    sortBy: 'dateAdded',
    sortType: 'desc',
    size: 5,
  },
  dateFields: [],
  sortFields: [],
  protectedFields: ['idStatus'],
};

export const notificationsRevisionsView: SearchEngineViewConfig<
  RevisionSearchParams,
  RevisionDateField,
  RevisionSortField,
  SearchEngineView
> = {
  id: 'notificationsRevisions',
  defaultParams: {
    sortBy: 'dateAdded',
    sortType: 'desc',
    size: 20,
  },
  dateFields: ['dateAdded'],
  sortFields: ['dateAdded'],
  protectedFields: ['idStatus'],
};

export const notificationsRevisionsPopoverView: SearchEngineViewConfig<
  RevisionSearchParams,
  RevisionDateField,
  RevisionSortField,
  SearchEngineView
> = {
  id: 'notificationsPopoverRevisions',
  defaultParams: {
    sortBy: 'dateAdded',
    sortType: 'desc',
    size: 5,
  },
  dateFields: [],
  sortFields: [],
  protectedFields: ['idStatus'],
};

export const broadcastMessagesView: SearchEngineViewConfig<
  BroadcastMessageSearchParams,
  BroadcastMessageDateField,
  BroadcastMessageSortField,
  SearchEngineView
> = {
  id: 'broadcastMessages',
  defaultParams: {
    sortBy: 'dateAdded',
    sortType: 'desc',
    isHistoric: false,
    isRead: false,
    size: 10,
  },
  dateFields: [],
  sortFields: [],
  protectedFields: ['isHistoric', 'isRead'],
};

export const notificationsPopoverBroadcastMessagesView: SearchEngineViewConfig<
  BroadcastMessageSearchParams,
  BroadcastMessageDateField,
  BroadcastMessageSortField,
  SearchEngineView
> = {
  id: 'notificationsPopoverBroadcastMessages',
  defaultParams: {
    sortBy: 'dateAdded',
    sortType: 'desc',
    isHistoric: false,
    isRead: false,
    size: 5,
  },
  dateFields: [],
  sortFields: [],
  protectedFields: ['isHistoric', 'isRead'],
};

export const notificationsBroadcastMessagesView: SearchEngineViewConfig<
  BroadcastMessageSearchParams,
  BroadcastMessageDateField,
  BroadcastMessageSortField,
  SearchEngineView
> = {
  id: 'notificationsBroadcastMessages',
  defaultParams: {
    sortBy: 'dateAdded',
    sortType: 'desc',
    isHistoric: false,
    isRead: false,
    size: 10,
  },
  dateFields: [],
  sortFields: [],
  protectedFields: ['isHistoric', 'isRead'],
};

export const notificationsPopoverHistoricBroadcastMessagesView: SearchEngineViewConfig<
  BroadcastMessageSearchParams,
  BroadcastMessageDateField,
  BroadcastMessageSortField,
  SearchEngineView
> = {
  id: 'notificationsPopoverHistoricBroadcastMessages',
  defaultParams: {
    sortBy: 'dateAdded',
    sortType: 'desc',
    isHistoric: true,
    isRead: false,
    size: 5,
  },
  dateFields: [],
  sortFields: [],
  protectedFields: ['isHistoric', 'isRead'],
};

export const notificationsHistoricBroadcastMessagesView: SearchEngineViewConfig<
  BroadcastMessageSearchParams,
  BroadcastMessageDateField,
  BroadcastMessageSortField,
  SearchEngineView
> = {
  id: 'notificationsHistoricBroadcastMessages',
  defaultParams: {
    sortBy: 'dateAdded',
    sortType: 'desc',
    isHistoric: true,
    isRead: false,
    size: 10,
  },
  dateFields: [],
  sortFields: [],
  protectedFields: ['isHistoric', 'isRead'],
};

export const orderDrawingsView: SearchEngineViewConfig<
  OrderDrawingSearchParams,
  OrderDrawingDateField,
  OrderDrawingSortField,
  SearchEngineView
> = {
  id: 'orderDrawings',
  defaultParams: {
    sortBy: 'dateStarted',
    sortType: 'desc',
    size: 10,
  },
  dateFields: ['dateStarted', 'dateDue'],
  sortFields: ['dateStarted', 'dateDue'],
  protectedFields: ['idStatus', 'canApplyPreferred'],
};

export const orderOffersView: SearchEngineViewConfig<
  OrderDrawingSearchParams,
  OrderDrawingDateField,
  OrderDrawingSortField,
  SearchEngineView
> = {
  ...orderDrawingsView,
  id: 'orderOffers',
};

export const viewConfigs: SearchEngineViewConfig<any, any, any, SearchEngineView>[] = [
  availableOrdersView,
  revisionsView,
  revisionsOrderView,
  ownFilesView,
  writerPassesView,
  gradingsView,
  ticketsView,
  writerPaymentBalanceView,
  writerPaymentBatchesView,
  feedbacksView,
  gradingsOrderView,
  writerOrdersView,
  fieldCompetencesView,
  notificationsOrdersView,
  notificationsRevisionsView,
  notificationsOrdersPopoverView,
  notificationsRevisionsPopoverView,
  broadcastMessagesView,
  notificationsPopoverBroadcastMessagesView,
  notificationsBroadcastMessagesView,
  notificationsPopoverHistoricBroadcastMessagesView,
  notificationsHistoricBroadcastMessagesView,
  orderDrawingsView,
  orderOffersView,
];
