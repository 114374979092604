import { API_PATH } from '@/config/features';
import { emptyApi as api, getTags } from '@/store/emptyApi';
import { WriterOrderOwnFileAssociation } from '@/types/file/ownFile';
import { AICheck, PlagCheck } from '@/types/plagAICheck';
import { SearchView } from '@/types/search';
import { Ticket } from '@/types/ticket';
import {
  UpdateWriterOrderFilesRequest,
  UpdateWriterOrderStatusRequest,
  WriterOrderDetails,
  WriterOrderSearchItem,
  WriterOrderSearchParams,
} from '@/types/writerOrder';
import { WriterOrderCustomerExternalCredential } from '@/types/customerExternalCredential';

// Entity
const type = 'WriterOrder';
const path = API_PATH[type];

const { providesTags } = getTags(type);

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchWriterOrders: build.query<SearchView<WriterOrderSearchItem>, WriterOrderSearchParams>({
      query: (params) => ({ url: `${path}`, params }),
      providesTags: () => [{ type, id: 'search' }],
    }),
    getWriterOrder: build.query<WriterOrderDetails, number>({
      query: (id) => ({ url: `${path}/${id}` }),
      providesTags,
    }),
    withdrawWriterOrder: build.mutation<void, UpdateWriterOrderStatusRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/withdraw`, body, method: 'DELETE' }),
    }),
    acceptWriterOrder: build.mutation<void, UpdateWriterOrderStatusRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/accept`, body, method: 'PATCH' }),
    }),
    rejectWriterOrder: build.mutation<void, UpdateWriterOrderStatusRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/reject`, body, method: 'PATCH' }),
    }),
    submitWriterOrder: build.mutation<void, UpdateWriterOrderStatusRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/submit`, body, method: 'PATCH' }),
    }),
    continueWriterOrder: build.mutation<void, UpdateWriterOrderStatusRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/continue`, body, method: 'PATCH' }),
    }),

    searchWriterOrderDraftFiles: build.query<WriterOrderOwnFileAssociation[], number>({
      query: (id) => ({ url: `${path}/${id}/a/draft-writer-files` }),
      providesTags: (result, error, id) => [{ type, id: 'writerDraftFiles' + id }],
    }),
    claimWriterOrderDraftFiles: build.mutation<WriterOrderOwnFileAssociation, UpdateWriterOrderFilesRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/a/draft-writer-files/claim`, method: 'PUT', body }),
      invalidatesTags: (result, error, { id }) => [{ type, id: 'writerDraftFiles' + id }],
    }),
    withdrawWriterOrderDraftFiles: build.mutation<WriterOrderOwnFileAssociation, UpdateWriterOrderFilesRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/a/draft-writer-files/withdraw`, method: 'DELETE', body }),
      invalidatesTags: (result, error, { id }) => [{ type, id: 'writerDraftFiles' + id }],
    }),

    searchWriterOrderFiles: build.query<WriterOrderOwnFileAssociation[], number>({
      query: (id) => ({ url: `${path}/${id}/a/writer-files` }),
      providesTags: (result, error, id) => [{ type, id: 'writerFiles' + id }],
    }),
    claimWriterOrderFiles: build.mutation<WriterOrderOwnFileAssociation, UpdateWriterOrderFilesRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/a/writer-files/claim`, method: 'PUT', body }),
      invalidatesTags: (result, error, { id }) => [{ type, id: 'writerFiles' + id }],
    }),
    withdrawWriterOrderFiles: build.mutation<WriterOrderOwnFileAssociation, UpdateWriterOrderFilesRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/a/writer-files/withdraw`, method: 'DELETE', body }),
      invalidatesTags: (result, error, { id }) => [{ type, id: 'writerFiles' + id }],
    }),

    searchWriterOrderPlagChecks: build.query<PlagCheck[], number>({
      query: (id) => ({ url: `${path}/${id}/plag-checks` }),
      providesTags: (result, error, id) => [{ type, id: 'plagChecks' + id }],
    }),
    searchWriterOrderAIChecks: build.query<AICheck[], number>({
      query: (id) => ({ url: `${path}/${id}/ai-checks` }),
      providesTags: (result, error, id) => [{ type, id: 'aiChecks' + id }],
    }),
    getWriterOrderTickets: build.query<Ticket[], number>({
      query: (id) => ({ url: `${path}/${id}/tickets` }),
      providesTags: (result, error, id) => [{ type, id: 'tickets' + id }, 'Ticket'],
    }),
    getWriterOrderGradings: build.query<Ticket[], number>({
      query: (id) => ({ url: `${path}/${id}/gradings` }),
      providesTags: (result, error, id) => [{ type, id: 'gradings' + id }],
    }),
    getWriterOrderLoginDetails: build.query<WriterOrderCustomerExternalCredential[], number>({
      query: (id) => ({ url: `${path}/${id}/order/a/customer-external-credentials` }),
      providesTags: (result, error, id) => [{ type, id: 'loginDetails' + id }],
    }),
  }),
  overrideExisting: false,
});

export { injectedRtkApi as writerOrderApi };

export const {
  useSearchWriterOrdersQuery,
  useLazySearchWriterOrdersQuery,
  useGetWriterOrderQuery,
  useLazyGetWriterOrderQuery,

  useSearchWriterOrderFilesQuery,
  useLazySearchWriterOrderFilesQuery,
  useClaimWriterOrderFilesMutation,
  useWithdrawWriterOrderFilesMutation,

  useLazySearchWriterOrderDraftFilesQuery,
  useClaimWriterOrderDraftFilesMutation,
  useWithdrawWriterOrderDraftFilesMutation,

  useWithdrawWriterOrderMutation,
  useAcceptWriterOrderMutation,
  useRejectWriterOrderMutation,
  useSubmitWriterOrderMutation,
  useContinueWriterOrderMutation,
  useGetWriterOrderTicketsQuery,
  useSearchWriterOrderPlagChecksQuery,
  useLazySearchWriterOrderPlagChecksQuery,
  useSearchWriterOrderAIChecksQuery,
  useLazySearchWriterOrderAIChecksQuery,
  useGetWriterOrderGradingsQuery,
  useLazyGetWriterOrderGradingsQuery,
  useLazyGetWriterOrderLoginDetailsQuery,
} = injectedRtkApi;
