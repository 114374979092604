import { useAuth } from '@/store/auth/authHooks';
import { loadChatScript } from '@feature/chat/chat';
import { capitalize, lowerCase, upperFirst } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CHATWOOT_TOKEN } from '@/config/constants';
import { usePreferences } from '@/hooks/preferences';

const _log = (...args) => console.log('%cChatwoot', 'background:purple;color:white;', ...args);

export const ChatwootWidget = () => {
  const { preferences } = usePreferences();

  const { user: writer } = useAuth();
  const { pathname } = useLocation();
  const [prevUserId, setPrevUserId] = useState(writer.id); // fix some issues with magic link

  const [chatInitialized, setChatInitialized] = useState(false);
  const checkCounterRef = useRef(0);

  const signInUser = useCallback(() => {
    _log('Sign in');
    const userName = `${writer.firstName || ''} ${writer.lastName || ''}`.replaceAll('-', '').trim() || 'writer';

    /* eslint-disable camelcase */
    const userInfo = {
      avatar_url: preferences.avatar,
      name: `WR • #${writer.id} • ${capitalize(userName)}`,
      email: writer.primaryEmail?.address || '',
      phone_number: writer.primaryPhone ? '+' + writer.primaryPhone.idCallingCode + writer.primaryPhone.number : '',
      company_name: window.location.host.replace(/.*?\.(.*?)\..*/, '$1'),
    };

    window['$chatwoot'].setUser(`uid ${writer.id}`, userInfo);

    // _log(`setUser [${writer.id}]`, userInfo, writer);
  }, [preferences.avatar, writer]);
  const resetChat = useCallback(() => {
    if (!chatInitialized) return;
    window['$chatwoot'].reset();
  }, [chatInitialized]);

  /**
   * Sync chat with writer session / setUser
   */
  useEffect(() => {
    if (!chatInitialized) return; // _log('Not ready yet');

    if (writer.id !== prevUserId) {
      setPrevUserId(writer.id);
      if (prevUserId) {
        resetChat();
      }
    }

    if (writer.id && writer.id === prevUserId) {
      setTimeout(() => {
        signInUser();
      }, 1000);
    }
  }, [writer.id, prevUserId, chatInitialized, signInUser, resetChat]);

  /**
   * Sync navigation / setCustomAttributes
   */
  const getTitleFromPath = (path: string) => {
    const pathValue = String(path).trim();
    if (!pathValue) return '';
    return upperFirst(lowerCase(pathValue));
  };

  const setCustomAttributes = useCallback(() => {
    if (!chatInitialized) return;
    const { origin } = window.location;

    let path = {
      title: `${getTitleFromPath(pathname)}`,
      url: `${origin}${pathname}`,
      email: `${writer.primaryEmail?.address || 'unknown'}`,
    };

    window['$chatwoot'].setCustomAttributes(path);
  }, [chatInitialized, pathname, writer.primaryEmail?.address]);

  useEffect(setCustomAttributes, [pathname, setCustomAttributes]);

  /**
   * Check availability
   */
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    const checkChatwoot = () => {
      checkCounterRef.current++;
      if (window['$chatwoot'] !== undefined && window['$chatwoot'].hasLoaded) {
        setChatInitialized(true);
        _log('Initialized successfully');
      } else {
        if (checkCounterRef.current >= 10) return _log('Unavailable');
        timer = setTimeout(checkChatwoot, 500 * checkCounterRef.current);
      }
    };

    window.addEventListener('chatwoot:ready', checkChatwoot);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('chatwoot:ready', checkChatwoot);
    };
  }, []);

  /**
   * Init chat on preferences fetched!
   */
  useEffect(() => {
    loadChatScript(CHATWOOT_TOKEN);
  }, []);

  return null;
};
