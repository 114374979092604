import { useEffect, useMemo, useState } from 'react';
import { Button, Drawer, Form, Space, Tooltip } from 'antd';
import { debounce, range } from 'lodash';
import type { GeneralSearchParams, SearchEngineProps } from '../types';
import { getAppliedFiltersCount, LabelsResolver } from '../helpers';
import { SearchEnginePagination } from './SearchEnginePagination';
import { SearchEngineResetFiltersMenu } from './SearchEngineResetFiltersMenu';
import { SearchSortPopover } from './SearchSortPopover';
import { SearchDateFilters } from './dateRange/SearchDateFilters';

import cn from 'classnames';

export const CoreSearchEngine = <T extends GeneralSearchParams>({
  config,
  children,
  controls,
  params,
  setParams,
  total,
  saveParams,
  searchField,
  panelRight,
  labels,
  layout,
  counterComponent: CounterComponent,
  icons,
  pageSizeOptions = range(1, 101),
  views,
  extra,
  className,
  drawerClassName,
  onSearchParamsChange,
  showControls = {
    dateFields: true,
    sortFields: true,
    resetFilters: true,
  },
  showPagination = true,
  overrides,
  debounceMs = 300,
  extraControls,
  extendedSearchMode,
  onExtendedSearchModeChange,
  controlledFields,
}: SearchEngineProps<T>) => {
  const t = new LabelsResolver(labels);

  const {
    filter: filterIcon,
    resetFilterMenu,
    resetToDefaults,
    clearFilters,
    sortAsc,
    sortDesc,
    sortAscAlt,
    sortDescAlt,
    dateRange,
    closeDatePicker,
    showExtendedControls,
    hideExtendedControls,
  } = icons;

  const [form] = Form.useForm();
  const [drawerVisible, setDrawerVisible] = useState(false);

  const { dateFields, sortFields, protectedFields, defaultParams, id } = config;

  const defaults = useMemo(() => ({ size: 10, page: 1, ...defaultParams, ...(overrides || {}) }), [defaultParams, overrides]);

  const handleSubmit = (nextParams: Record<string, any>) => {
    setParams((params) => ({ ...params, ...nextParams, page: 1, ...(overrides || {}) }));
  };

  const handleValuesChange = debounce(form.submit, debounceMs);

  const handlePaginationChange = (page: number, size?: number) => {
    setParams((params) => ({ ...params, page, size, ...(overrides || {}) }));
  };

  const filterCounter = getAppliedFiltersCount(params, protectedFields);

  useEffect(() => {
    saveParams && onSearchParamsChange?.(id, params);
  }, [params, id, saveParams, views, onSearchParamsChange]);

  useEffect(() => {
    if (!controlledFields) return;
    const updatedParams = {};
    const formValues = Object.entries(form.getFieldsValue());
    const currentParams = Object.entries(params);
    formValues.forEach(([formKey, formValue]) => {
      if (!(controlledFields as string[]).includes(formKey)) return;
      const paramValue = currentParams.find(([paramKey]) => paramKey === formKey)?.[1];
      if (paramValue !== formValue) {
        updatedParams[formKey] = paramValue;
      }
    });
    form.setFieldsValue(updatedParams);
  }, [controlledFields, params, form]);

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const toggleExtraControls = () => {
    onExtendedSearchModeChange?.(!extendedSearchMode);
  };

  return (
    <>
      <section
        className={cn(
          'core-search-engine',
          {
            'core-search-engine--extra-controls': extraControls && extendedSearchMode && layout === 'desktop',
          },
          className,
        )}
      >
        <Form
          initialValues={params}
          form={form}
          onValuesChange={handleValuesChange}
          onFinish={handleSubmit}
          autoComplete="off"
          layout="inline"
        >
          <div className="core-search-engine__panel">
            {controls && (
              <div className="core-search-engine__controls">
                {searchField}

                {layout === 'desktop' && controls}

                <Space>
                  {layout === 'desktop' && !!dateFields?.length && showControls.dateFields && (
                    <SearchDateFilters
                      params={params}
                      onChange={setParams}
                      keys={dateFields}
                      dateRangeIcon={dateRange}
                      isPopover
                      closeDatePickerIcon={closeDatePicker}
                      t={t}
                    />
                  )}

                  {!!sortFields?.length && showControls.sortFields && (
                    <SearchSortPopover
                      keys={sortFields}
                      sortAscIcon={sortAsc}
                      sortDescIcon={sortDesc}
                      sortDescAltIcon={sortDescAlt}
                      sortAscAltIcon={sortAscAlt}
                      sortType={params.sortType}
                      layout={layout}
                      t={t}
                    />
                  )}

                  {layout === 'desktop' && extraControls && (
                    <Tooltip title={t.resolve(extendedSearchMode ? 'hideExtendedControls' : 'showExtendedControls')}>
                      <Button onClick={toggleExtraControls} className="ant-btn-square">
                        {extendedSearchMode ? hideExtendedControls : showExtendedControls}
                      </Button>
                    </Tooltip>
                  )}

                  {layout === 'desktop' && showControls.resetFilters && (
                    <CounterComponent count={filterCounter}>
                      <SearchEngineResetFiltersMenu
                        defaultParams={defaults}
                        protectedFields={protectedFields}
                        setParams={setParams}
                        resetFilterMenuIcon={resetFilterMenu}
                        resetToDefaultsIcon={resetToDefaults}
                        clearFiltersIcon={clearFilters}
                        view={layout}
                        t={t}
                      />
                    </CounterComponent>
                  )}

                  {layout === 'mobile' && (
                    <CounterComponent count={filterCounter}>
                      <Button className="ant-btn-square" onClick={() => setDrawerVisible(true)}>
                        {filterIcon}
                      </Button>
                    </CounterComponent>
                  )}
                </Space>
              </div>
            )}
            {panelRight && <div className="core-search-engine__panel-right">{panelRight}</div>}
          </div>

          <div className="core-search-engine__main">
            <div className="core-search-engine__children">{children}</div>
            {extraControls && extendedSearchMode && layout === 'desktop' && (
              <div className="core-search-engine__extra-controls">{extraControls}</div>
            )}
          </div>

          <Form.Item hidden>
            <Button htmlType="submit">{t.resolve('search')}</Button>
          </Form.Item>
        </Form>

        {((!!total && showPagination) || extra) && (
          <Space align="center" className="core-search-engine__bottom">
            {!!total && showPagination && (
              <SearchEnginePagination
                page={params.page}
                size={params.size}
                total={total}
                onChange={handlePaginationChange}
                t={t}
                pageSizeOptions={pageSizeOptions}
              />
            )}
            {extra && <div className="core-search-engine__extra">{extra}</div>}
          </Space>
        )}
      </section>

      {layout === 'mobile' && (
        <Drawer
          width={300}
          placement="right"
          visible={drawerVisible}
          closeIcon={false}
          title={
            <Space>
              {filterIcon}
              <span>{t.resolve('filters')}</span>
            </Space>
          }
          onClose={closeDrawer}
          className={cn('core-search-engine__drawer', drawerClassName)}
        >
          <div className="core-search-engine-drawer-form">
            <div className="core-search-engine-drawer-form__inner">
              <Form form={form} autoComplete="off" onValuesChange={handleValuesChange} onFinish={handleSubmit}>
                {showControls.resetFilters && (
                  <SearchEngineResetFiltersMenu
                    defaultParams={defaults}
                    protectedFields={protectedFields}
                    setParams={setParams}
                    resetFilterMenuIcon={resetFilterMenu}
                    resetToDefaultsIcon={resetToDefaults}
                    clearFiltersIcon={clearFilters}
                    view={layout}
                    t={t}
                  />
                )}
                {controls}
              </Form>

              {!!dateFields?.length && showControls.dateFields && (
                <SearchDateFilters
                  params={params}
                  onChange={setParams}
                  keys={dateFields}
                  isPopover={false}
                  dateRangeIcon={dateRange}
                  closeDatePickerIcon={closeDatePicker}
                  t={t}
                />
              )}
              {extraControls && (
                <Button type="primary" className="core-search-engine-drawer-extra-controls-button " onClick={toggleExtraControls}>
                  {t.resolve(extendedSearchMode ? 'hideExtendedControls' : 'showExtendedControls')}
                </Button>
              )}
              {extraControls && extendedSearchMode && <div className="core-search-engine__extra-controls">{extraControls}</div>}
            </div>
            <Space size={16} direction="vertical" style={{ width: '100%' }}>
              <Button className="core-search-engine-drawer-button" onClick={closeDrawer}>
                {t.resolve('close')}
              </Button>
            </Space>
          </div>
        </Drawer>
      )}
    </>
  );
};
