/**
 * Deadline remaining countdown component
 * Using for Paid order.
 * Check also: DeadlineShiftClock
 * Check docs: https://github.com/jsmreese/moment-duration-format
 */
import cn from 'classnames';
import momentDurationFormatSetup from 'moment-duration-format';
import moment from 'moment-timezone';

// Styles
import s from './TimeCountDown.module.less';
import { useDeadlineCountdown } from '@/hooks/deadline';
import { CSSProperties } from 'react';

interface Props {
  dueDate: string | undefined;

  markExpired?: boolean;
  colored?: boolean;

  format?: {
    expired?: string;
    default?: string;
  };
  style?: CSSProperties;
}

/*
 * Setup plugin (monkey patch moment instance)
 * https://github.com/jsmreese/moment-duration-format#usage
 */
// @ts-ignore
momentDurationFormatSetup(moment);

export const TimeCountDown = ({ dueDate, colored, markExpired = true, format, style }: Props) => {
  const { isExpired, remainingSeconds } = useDeadlineCountdown(dueDate, !markExpired);

  const displayAsExpired = markExpired && isExpired;

  const formatPattern = displayAsExpired
    ? format?.expired || '[Exp] y[y] w[w] d[d] h[h] ago'
    : format?.default || 'w[w] d[d] h[h] m[m] s[s]';

  const remainingTime = markExpired ? remainingSeconds : Math.abs(remainingSeconds);

  if (!dueDate) return null;

  return (
    <span className={cn(s.wrapper, { [s.expired]: displayAsExpired, [s.colored]: colored })} style={style}>
      {moment
        .duration(remainingTime, 'seconds')
        // @ts-ignore Important! coz: plugin "moment-duration-format" -> https://github.com/jsmreese/moment-duration-format#roadmap
        .format(formatPattern)}
    </span>
  );
};
