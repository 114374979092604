import { WorkTypeNatures } from './workType';
import { PaperUnitNames } from './paperUnit';
import { LineSpacingNames } from './lineSpacing';
import { AcademicDegrees } from './academicLevel';
import { OrderSnapshot, OrderSnapshotExtended } from '@/types/order';
import { WriterPass } from './writerPass';
import { WriterPayment } from './writerPayment';
import { LogMessage, Patch } from './common/api';
import { WriterOrderOwnFileAssociation } from './file/ownFile';
import { CommonSearchParams } from './search';
import { CitationStyleNames } from './citationStyle';
import { PaperTypeNames } from './paperType';
import { TicketCreatePayload } from './ticket';

export enum WriterOrderStatuses {
  Claimed = 1,
  Assigned,
  InWork,
  Submitted,
  Unassigned,
  Aborted,
  Dismissed,
  Paused,
  Withdrawn,
  Rejected,
  Pending,
  UnableToProceed = 13,
  Ready,
  Completed,
}

export enum OwnFileWriterOrderAssociationStatuses {
  Claimed = 1,
  Set,
  Confirmed,
  Removed,
}

export type WriterOrder = {
  id: number;
  isPrimary?: boolean;
  title?: string;
  idStatus: WriterOrderStatuses;
  idOrder: number;
};

export type WriterOrderExtended = WriterOrder & {
  dateAdded: string;
  dateSubmitted?: string;
  dateUnassigned?: string;
};

export type WriterOrderSearchItem = {
  id: number;
  isPrimary?: boolean;
  title?: string;
  idStatus: WriterOrderStatuses;
  idOrder: number;
  writerPass?: WriterPass;
  originalSnapshot: OrderSnapshot;
  currentSnapshot?: OrderSnapshot;
};

export type WriterOrderDetails = Omit<WriterOrderSearchItem, 'currentSnapshot'> & {
  currentSnapshot?: OrderSnapshotExtended;
  defaultsPaid?: number;
  bonusesPaid?: number;
  penaltiesApplied?: number;
  defaultsUnpaid?: number;
};

export type OwnFileWriterOrderAssociation = {
  dateAdded: string;
  idStatus: OwnFileWriterOrderAssociationStatuses;
  writerOrder: WriterOrderExtended;
};

export type ActiveOrderWriterOrder = {
  id: number;
  title?: string;
  idStatus: WriterOrderStatuses;
  writerPayment?: WriterPayment;
  associationWriterFiles: WriterOrderOwnFileAssociation[];
};

export type WriterOrderSearchParams = {
  idFrom?: number;
  idTo?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  isPrimary?: boolean;
  idStatus?: WriterOrderStatuses[];
  idWriterPass?: number[];
  idOriginalSnapshot?: number[];
  idOrder?: number[];
  idFieldOfStudy?: number[];
  idAcademicDegree?: AcademicDegrees[];
  idCitationStyleName?: CitationStyleNames[];
  idLineSpacingName?: LineSpacingNames[];
  idPaperTypeName?: PaperTypeNames[];
  idPaperUnitName?: PaperUnitNames[];
  idWorkTypeNature?: WorkTypeNatures[];
  sortBy?: WriterOrderSortField;
} & CommonSearchParams;

export type WriterOrderSortField = 'id' | 'dateAdded' | 'idWriterPass' | 'idOriginalSnapshot' | 'idOrder';

export type WriterOrderDateField = 'dateAdded';

export type WithdrawWriterOrderPayload = LogMessage;

export type WithdrawWriterOrderRequest = Patch<WithdrawWriterOrderPayload>;

export type UpdateWriterOrderStatusRequest = Patch<LogMessage>;

export type UpdateWriterOrderFilesPayload = {
  ids: number[];
};

export type UpdateWriterOrderFilesRequest = Patch<UpdateWriterOrderFilesPayload>;

export type WriterOrderCreateTicketRequest = Patch<TicketCreatePayload>;
